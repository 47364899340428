import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.changeListener = event => this.setChanged();

    this.element.addEventListener('input', this.changeListener, { once: true, passive: true })
  }

  disconnect() {
    this.element.removeEventListener('input', this.changeListener, { once: true, passive: true })
  }

  setChanged() {
    var elements = this.element.querySelectorAll('[data-changes]');

    elements.forEach(e => {
      var classes = e.dataset.changes.split(' ');

      classes.forEach(c => {
        if(c.startsWith('-')) {
          e.classList.remove(c.substring(1));
        } else {
          e.classList.add(c);
        }
      });
    });
  }
}
