import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"];

  increment() {
    this.adjust(1)
  }

  decrement() {
    this.adjust(-1)
  }

  adjust(by) {
    this.value = this.value + by
  }

  get value() {
    let value = this.inputTarget.value
    if(!value) { value = 0 }
    return parseInt(value);
  }

  set value(value) {
    if(value < this.minValue) { value = this.minValue }
    if(value > this.maxValue) { value = this.maxValue }

    this.inputTarget.value = value;
    this.inputTarget.dispatchEvent(new Event("change"));
  }

  get minValue() {
    return parseInt(this.inputTarget.min) || 1;
  }

  get maxValue() {
    return parseInt(this.inputTarget.max) || 9999;
  }
}
