import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { url: String }

  connect() {
    Turbo.visit(this.urlValue, { action: 'replace', historyChanged: false })
  }

  // Make overlay permanent on next page, so current overlay stays active
  makeOverlayPermanent(event) {
    let overlay = event.detail.newBody.querySelector('#overlay');
    overlay.setAttribute('data-turbo-permanent', '');
  }
}
