import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['container', 'loader', 'modifier', 'force', 'currentPrice', 'priceChange'];
  static values = { priceUrl: String, currentPrice: Number };

  connect() {
    this.request_id = 0;
    this.cached_prices = {};
    this.refresh(false);
  }

  refresh(showLoader = true) {
    if(showLoader) {
      this.containerTargets.forEach(e => e.classList.add('opacity-25', 'pointer-events-none'));
      this.loaderTargets.forEach(e => e.classList.remove('hidden'));
    }

    this.request_id++;
    let request_params = this.priceParams();
    let params = request_params.toString();
    let cached_response = this.cached_prices[params];

    if(cached_response) {
      this.handleResponse(cached_response);
      return;
    }

    request_params.append('request_id', this.request_id);
    request_params.append('previous_price', this.currentPrice);

    let url = this.priceUrlValue + "?" + request_params;

    fetch(url, { headers: { 'Content-Type': 'application/json' }})
      .then(data => data.json())
      .then(json => {
        this.cached_prices[params] = json;

        // Don't show older responses
        if(json.request_id != this.request_id) {
          return
        }

        this.handleResponse(json);
      });
  }

  handleResponse(json) {
    let priceChanged = json.price != this.currentPrice;

    this.containerTargets.forEach(e => e.innerHTML = json.content);
    this.containerTargets.forEach(e => e.classList.remove('opacity-25', 'pointer-events-none'));

    this.loaderTargets.forEach(e => e.classList.add('hidden'));

    if(priceChanged) {
      this.priceChangeTargets.forEach(e => e.classList.add('animate-price-change'));
    }
  }

  priceParams() {
    let params = new URLSearchParams();

    // These targets always take precedence, ignoring other modifiers
    for(let e of this.forceTargets) {
      if(e.checked) {
        let key = e.dataset['wizard-PriceName'];
        let value = e.dataset['wizard-PriceValue'];

        params.append(key, value);

        return params;
      }
    }

    for(let e of this.modifierTargets) {
      if(e.type == 'number') {
        let key = e.dataset['wizard-PriceName'];
        let value = e.value;

        params.append(key, value);

      } else if(e.checked) {
        let key = e.dataset['wizard-PriceName'];
        let value = e.dataset['wizard-PriceValue'];

        params.append(key, value);
      }
    }

    return params;
  }

  get currentPrice() {
    if(!this.hasCurrentPriceTarget) { return 0; }

    return Number(this.currentPriceTarget.dataset['wizard-PriceAmount'] || 0);
  }
}
