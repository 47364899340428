import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.style.resize = 'none';
    this.element.style.minHeight = `${this.element.scrollHeight}px`;
  }

  resize(event) {
    event.target.style.height = '5px';
    event.target.style.height =  `${event.target.scrollHeight}px`;
  }
}
