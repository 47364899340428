import { Controller } from "@hotwired/stimulus"

const Uppy = require('@uppy/core')
const Dashboard = require('@uppy/dashboard')
const DutchLocale = require('@uppy/locales/lib/nl_NL')
const Form = require('@uppy/form')
const ActiveStorageUpload = require('uppy-activestorage-upload')

require('@uppy/core/dist/style.css')
require('@uppy/dashboard/dist/style.css')

export default class extends Controller {
  static targets = ['dashboard', 'form', 'disable', 'changer'];
  static values = { directUploadUrl: String, allowedFiles: String }

  connect() {
    let direct_upload_url = this.directUploadUrlValue;
    const image_file_types = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.tif', '.tiff', 'jfif'];

    let options = {
      allowMultipleUploads: false,
      locale: DutchLocale,
    };

    let browse = "Bestand(en) toevoegen";

    if(this.allowedFilesValue == "images") {
      options.restrictions = {
        allowedFileTypes: image_file_types,
      }

      browse = "Afbeelding(en) toevoegen";
    } else if(this.allowedFilesValue == "invoices") {
      options.restrictions = {
        allowedFileTypes: image_file_types.concat(['.pdf']),
      }

      browse = "Afhaalbewijs(en) toevoegen";
    } else if(this.allowedFilesValue == "analysis") {
      options.restrictions = {
        maxNumberOfFiles: 1,
        allowedFileTypes: ['.pdf'],
      }

      browse = "Afhaalbewijs toevoegen";
    }

    let uppy = new Uppy(options);

    this.uppy = uppy;

    uppy.use(ActiveStorageUpload, {
      directUploadUrl: direct_upload_url
    });

    uppy.use(Dashboard, {
      inline: true,
      target: this.dashboardTarget,
      hideUploadButton: true,
      showProgressDetails: true,
      proudlyDisplayPoweredByUppy: false,
      locale: {
          strings: {
              browse: browse,
              dropPasteBoth: '%{browse}',
              dropPasteFiles: '%{browse}',
              dropPasteFolders: '%{browse}',
          },
      },
    });

    uppy.use(Form, {
      target: this.formTarget,
      resultName: 'uppyResult',
      triggerUploadOnSubmit: true,
      submitOnSuccess: true,
    });

    uppy.on('files-added', this.filesChanged.bind(this));
    uppy.on('file-removed', this.filesChanged.bind(this));

    uppy.on('upload', (result) => {
      for(let e of this.disableTargets) {
        e.disabled = true;
      }
    });

    this.filesChanged();
  }

  disconnect() {
    if(this.uppy) { this.uppy.close() }
  }

  filesChanged() {
    let files_present = this.uppy.getFiles().length > 0;

    this.changerTargets.forEach(element => {
      if(element.dataset.filesPresentClasses) {
        var classes = element.dataset.filesPresentClasses.split(' ');

        if(files_present) {
          element.classList.add(...classes);
        } else {
          element.classList.remove(...classes);
        }
      }

      if(element.dataset.filesEmptyClasses) {
        var classes = element.dataset.filesEmptyClasses.split(' ');

        if(files_present) {
          element.classList.remove(...classes);
        } else {
          element.classList.add(...classes);
        }
      }
    });
  }
}
