import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "slider", "senderPrice", "highWarning"];
  static values = { min: Number,
                    max: Number,
                    feeFixedPercentage: Number,
                    feeSteps: Object,
                    feeMin: Number,
                    feeRounding: Number,
                    feeInsurance: Number,
                    taxRate: Number,
                    taxFree: Boolean,
                    highWarning: Number };

  connect() {
    this.sliderToInput();
    this.increment = Number(this.inputTarget.step) * 100;
  }

  sliderChange() {
    this.sliderToInput();
  }

  inc() {
    this.setPrice(Number(this.sliderTarget.value) + this.increment);
  }

  dec() {
    this.setPrice(Number(this.sliderTarget.value) - this.increment);
  }

  setPrice(value) {
    value = Math.round(value/this.increment)*this.increment;

    if(value < this.minValue) { value = this.minValue; }
    if(value > this.maxValue) { value = this.maxValue; }

    this.sliderTarget.min = this.minValue + ((value - this.minValue) % 100);
    this.sliderTarget.value = value;

    this.sliderToInput();
  }

  sliderToInput() {
    this.inputTarget.value = Number(this.sliderTarget.value) / 100;
    this.updateSenderPrice();
  }

  inputToSlider() {
    var clamp = document.activeElement != this.inputTarget;
    var value = this.inputTarget.value * 100;

    if(clamp) {
      if(value < this.minValue) { value = this.minValue; }
      if(value > this.maxValue) { value = this.maxValue; }

      this.setPrice(value);
    } else if(value >= this.minValue && value <= this.maxValue) {
      this.sliderTarget.value = value;
      this.updateSenderPrice();
    }
  }

  updateSenderPrice() {
    var driver_price = Number(this.sliderTarget.value);
    var fee_base = this.calculateFee(driver_price)

    var fee_tax = fee_base * this.taxRateValue;
    if(this.taxFreeValue) { fee_tax = 0 }

    var total = driver_price + fee_base + fee_tax;
    total = Math.ceil(total/this.feeRoundingValue)*this.feeRoundingValue;

    total += this.feeInsuranceValue;

    var price = (total / 100.0).toFixed(2);
    this.senderPriceTarget.innerHTML = "€" + price;

    if(this.hasHighWarningTarget) {
      var too_high = this.highWarningValue > 0 && this.highWarningValue < Number(this.sliderTarget.value);
      this.highWarningTarget.classList.toggle('opacity-0', !too_high);
    }
  }

  calculateFee(price) {
    if(price <= 0) { return 0; }

    if(this.hasFeeFixedPercentageValue) {
      return Math.round(price * this.feeFixedPercentageValue);
    }

    var fee = this.calculateFeeInSteps(price, this.feeStepsValue);

    if(fee < this.feeMinValue) { fee = this.feeMinValue }

    return fee;
  }

  calculateFeeInSteps(price, steps) {
    let entries = Object.entries(steps);

    let ranges = entries.map(function(min, index) {
      let max = entries[index + 1];

      return {
        from: Number(min[0]) * 100,
        to: Number(max?.[0]) * 100,
        percentage: min[1],
      };
    });

    let fee = 0;

    for(let range of ranges) {
      let offset = price - range.from;
      if(offset < 0) { offset = 0 }
      if(range.to && offset > (range.to - range.from)) { offset = range.to - range.from }

      fee += offset * range.percentage;
    }

    return Math.ceil(fee);
  }
}
