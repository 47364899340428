import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["textarea", "saveButton"];
  static values = { defaultText: String, saved: String };

  connect() {
    this.defaultText = this.defaultTextValue;
  }

  save(event) {
    event.preventDefault();

    this.saveButtonTarget.classList.add('animate-pulse', 'pointer-events-none');

    var new_default_comment = this.textareaTarget.value;
    var data = new FormData();
    data.append('user[default_offer_comment]', new_default_comment);

    this.storeComment(new_default_comment)
        .then(function() {
          this.defaultText = new_default_comment;
          this.saveButtonTarget.classList.remove('animate-pulse', 'pointer-events-none');
          this.showToast();
        }.bind(this));
  }

  restore() {
    event.preventDefault();
    this.textareaTarget.value = this.defaultText;
  }

  async storeComment(comment) {
    const token = document.getElementsByName("csrf-token")[0].content;
    const url = '/account/profile';

    var data = new FormData();
    data.append('authenticity_token', token);
    data.append('user[default_offer_comment]', comment);

    var req = await fetch(url, {
      method: 'PATCH',
      body: data,
    });

    return req;
  }

  showToast() {
    var toast = document.createElement('div');
    toast.classList.add('toast');
    toast.dataset.controller="toast";
    toast.dataset.action="turbo:visit@window->toast#cleanup";
    toast.innerHTML = this.savedValue;

    document.getElementById('toasts').appendChild(toast);
  }
}
