// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")

// import 'core-js/stable'
// import 'regenerator-runtime/runtime'

require("stylesheets/application.scss")
require.context('../images', true)

import "controllers"


// Scroll to first error in form
document.addEventListener('DOMContentLoaded', function() {
  var field = document.querySelector('.field_with_errors')

  if(field) {
    field.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
});


window.addEventListener('DOMContentLoaded', function() {
  var fields = document.querySelectorAll('.field_with_errors');

  fields.forEach((field) => {
    var input = field.querySelector('input');
    var error = field.querySelector('.form-error');

    if(input && error) {
      input.addEventListener('input', () => {
        field.classList.remove('field_with_errors');
        error.remove();
      }, { once: true });
    }
  });
});

