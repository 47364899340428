import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container", "button"];

  connect() {
    this.buttonTargets[0].classList.add('current');
    this.setIndicator();
  }

  scrollTo(event) {
    event.preventDefault();
    // document.querySelector(event.target.getAttribute('href')).scrollIntoView();

    this.scrollToIndex(parseInt(event.target.dataset.photoIndex));
  }

  scrollToIndex(index) {
    index = (index + this.numPhotos) % this.numPhotos;

    var offset = index * this.containerTarget.clientWidth;
    this.containerTarget.scrollLeft = offset;
  }

  setIndicator() {
    var width = this.containerTarget.clientWidth;
    var left = this.containerTarget.scrollLeft;
    var index = Math.floor((left / width) + 0.5);

    this.buttonTargets.forEach(e => e.classList.remove('current'));
    this.buttonTargets[index].classList.add('current');
  }


  next(event) {
    event.preventDefault();
    this.scrollToIndex(this.currentIndex + 1);
  }

  prev(event) {
    event.preventDefault();
    this.scrollToIndex(this.currentIndex - 1);
  }

  get currentIndex() {
    var width = this.containerTarget.clientWidth;
    var left = this.containerTarget.scrollLeft;
    return Math.floor((left / width) + 0.5);
  }

  get numPhotos() {
    return Math.round(this.containerTarget.scrollWidth / this.containerTarget.clientWidth)
  }
}
