import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { coord: Object };

  connect() {
    this.view = new google.maps.StreetViewPanorama(
      this.element,
      {
        position: this.coordValue,
        addressControl: false,
        fullscreenControl: false,
        linksControl: false,
        enableCloseButton: false,
      },
    );
  }
}
