// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js.

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import { Dropdown } from "tailwindcss-stimulus-components"

window.Stimulus = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
Stimulus.load(definitionsFromContext(context))

window.Stimulus.register('dropdown', Dropdown)

window.stimulus_app = Stimulus;
