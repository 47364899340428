import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { autoOpen: Boolean, url: String, closeable: { type: Boolean, default: true } }

  connect() {
    if(this.autoOpenValue) {
      this.open();
    }
  }

  open() {
    if(!this.isOpen()) {
      closingOverlays();

      this.element.classList.add('overlay-open');
      pushOverlay(this, this.urlValue);

      this.dispatch("open");
    }

    this.setScrollbarWidth();
    document.body.setAttribute('noscroll', '');
  }

  close() {
    if(event) { event.preventDefault() }
    if(!this.closeableValue) { return; }

    popOverlay(this);

    this.element.classList.remove('overlay-open');
    document.body.removeAttribute('noscroll');

    this.dispatch("close");

    this.element.addEventListener('transitionend', () => {
      this.cleanup();
    }, { once: true });
  }

  cleanup() {
    document.body.removeAttribute('noscroll');
    document.activeElement.blur();

    this.element.classList.remove('overlay-open');

    if(this.element.parentNode) {
      this.element.parentNode.removeAttribute("src");
    }

    this.element.remove();
  }

  setScrollbarWidth() {
    var width = document.documentElement.style.getPropertyValue("--scrollbar-width");

    if(width == "") {
      const documentWidth = document.documentElement.clientWidth;
      const windowWidth = window.innerWidth;
      const scrollBarWidth = windowWidth - documentWidth;

      document.documentElement.style.setProperty("--scrollbar-width", `${scrollBarWidth}px`);
    }
  }

  isOpen() {
    return this.element.classList.contains('overlay-open');
  }
}
