import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { kind: String, message: Object };

  connect() {
    if(window.dataLayer == undefined) { return; };

    if(this.kindValue == 'ecommerce') {
      window.dataLayer.push({ [this.kindValue]: null });
    }

    window.dataLayer.push({ [this.kindValue]: this.messageValue });
  }
}
