import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['element'];

  mark(event) {
    for(let e of this.element.querySelectorAll('.current')) {
      e.classList.remove('current');
    }

    var element = event.target.closest('[data-current-clicker-target="element"]');
    if(element) {
      element.classList.add('current');
    }

    for (let e of element.querySelectorAll('[data-current-clicker-target="delete"]')) {
      e.remove();
    }
  }
}
