import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['loader'];

  connect() {
  }

  load(event) {
    console.log("Loading step");

    if(this.loadsInTurboFrame(event)) { return; }

    this.element.classList.add('pointer-events-none');

    this.loaderTargets.forEach(e => {
      e.classList.add('opacity-50');
      e.classList.remove('pointer-events-none');
    });
  }

  leave(event) {
    this.element.classList.remove('pointer-events-none');

    this.loaderTargets.forEach(e => {
      e.classList.remove('opacity-50');
      e.classList.add('pointer-events-none');
    });
  }

  loadsInTurboFrame(event) {
    if(!event.detail) { return false }
    if(!event.detail.formSubmission) { return false }
    if(!event.detail.formSubmission.delegate.element) { return false }

    return event.detail.formSubmission.delegate.element.nodeName == 'TURBO-FRAME';
  }
}
