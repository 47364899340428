import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.removeAttribute('data-controller');

    var container = document.getElementById('packages').querySelector('[data-controller="packages-filter"]');

    if(container) {
      var filterController = this.application.getControllerForElementAndIdentifier(container, 'packages-filter')
      filterController.reorder();
    }
  }
}
