import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['button', 'element'];

  connect() {
    this.startLoadingListener = this.startLoading.bind(this);
    this.formElement.addEventListener("submit", this.startLoadingListener);
  }

  disconnect() {
    this.formElement.removeEventListener("submit", this.startLoadingListener);
  }

  startLoading() {
    if(this.element.tagName == 'INPUT') {
      this.element.classList.add('button--loading');
    }

    for(let b of this.buttonTargets) {
      b.classList.add('button--loading');
    }

    for(let e of this.elementTargets) {
      let classes = e.dataset.formLoaderClasses.split(' ');
      e.classList.toggle(...classes);
    }
  }

  get formElement() {
    let e = this.element;

    while(e && e.tagName != "FORM") { e = e.parentNode }

    return e;
  }
}
