import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener("turbo:before-fetch-request", this.startLoader.bind(this))
  }

  startLoader() {
    this.setScrollbarWidth();
    document.body.setAttribute('noscroll', '');
  }

  setScrollbarWidth() {
    var width = document.documentElement.style.getPropertyValue("--scrollbar-width");

    if(width == "") {
      const documentWidth = document.documentElement.clientWidth;
      const windowWidth = window.innerWidth;
      const scrollBarWidth = windowWidth - documentWidth;

      document.documentElement.style.setProperty("--scrollbar-width", `${scrollBarWidth}px`);
    }
  }
}
