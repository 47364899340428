import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { id: String }

  connect() {
    this.load();
  }

  save() {
    let values = {}

    for (var input of this.inputs) {
      if(input.type == 'radio') {
        if(input.checked) {
          values[input.name] = input.value;
        }
      } else if (input.type == 'checkbox') {
        console.warn("Can't store checkbox values (yet)");
      } else if (input.name && input.value) {
        values[input.name] = input.value;
      }
    }

    this.saveValues(values);
  }

  load() {
    let values = this.storedValues();

    if(values == undefined) { return; }

    for (let input of this.inputs) {
      let value = values[input.name];

      if(value == undefined) { continue; }

      if(input.type == 'radio') {
        input.checked = value == input.value;
        input.dispatchEvent(new Event("change"));
      } else {
        // Temporarily disable step attribute before setting value if present
        if(input.getAttribute('step') != null) {
          var step = input.getAttribute('step');
          input.removeAttribute('step');

          setTimeout(function() { input.setAttribute('step', step); }, 10);
        }

        input.value = values[input.name];
        input.dispatchEvent(new Event("change"));
      }
    }
  }

  clear() {
    this.saveValues(null)
  }

  saveValues(values) {
    if(document._restore == undefined) { document._restore = {}; }
    document._restore[this.idValue] = values;
  }

  storedValues() {
    return document._restore?.[this.idValue];
  }

  get inputs() {
    return this.element.querySelectorAll('textarea[name], select[name], input[name]:not([type=submit]):not([type=hidden])');
  }
}
