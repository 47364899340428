import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr"

export default class extends Controller {
  static targets = ['tabs', 'stateDropdown', 'container', 'package', 'count', 'empty', 'province', 'pickup', 'size', 'date', 'order', 'group', 'nooffers', 'available', 'starred']
  static values = { reverseVisibility: Boolean }

  connect() {
    this.initializeCalendar();
    this.filter();
    this.reorder();
    this.activateVisibleState();
  }

  disconnect() {
    if(this.flatpickrInstance) {
      this.flatpickrInstance.destroy();
    }
  }

  initializeCalendar() {
    if(!this.hasDateTarget) {
      return;
    }

    this.selectedDates = new Set();
    var availableDates = this.packageTargets.map(e => e.dataset.date);

    this.flatpickrInstance = flatpickr(
      this.dateTarget,
      {
        dateFormat: "Y-m-d",
        enable: availableDates,
        inline: true,
        mode: "multiple",
        onChange: function(selectedDates, dateStr, _instance) {
          this.selectedDates.clear();

          for(let date of dateStr.split(',')) {
            date = date.trim();
            if(date != '') { this.selectedDates.add(date) }
          }

          this.filter();
        }.bind(this),
      }
    );
  }

  filter() {
    // Only filter if a filter is actually present
    if(!this.hasProvinceTarget) {
      return;
    }

    var values = {
      province: this.provinceTarget.value,
      pickup: this.pickupTarget.value,
      size: this.sizeTarget.value,
      dates: this.selectedDates,
      noOffers: this.nooffersTarget.checked,
      available: this.availableTarget.checked,
      starredOnly: this.starredTarget.value == 'starred',
    }

    this.runFilter(function(e) { return this.isVisible(e, values); }.bind(this));
  }

  filterOnState(event) {
    var state = event.target.dataset.packagesFilterState;

    if(event.target.nodeName == 'SELECT') {
      state = event.target.value;
    }

    if(state == undefined) { return; }

    for(let e of this.element.getElementsByClassName('current')) { e.classList.remove('current'); }
    event.target.classList.add('current');

    this.runFilter(function(e) {
      if(e.dataset.inStateOnly) {
        return state == e.dataset.state;
      }

      if(state == "") { return true; }
      return state == e.dataset.state;
    }.bind(this));

    this.hideEmptyGroups();
  }

  reorder() {
    // Only reorder if an order value is present
    if(!this.hasOrderTarget) {
      return;
    }

    var current_order = this.orderTarget.value;
    if(current_order == 'updated_desc') { this.sortPackages('updated', false); }
    else if(current_order == 'date_asc') { this.sortPackages('created', true); }
    else if(current_order == 'date_desc') { this.sortPackages('created', false); }
    else if(current_order == 'deadline_asc') { this.sortPackages('deadline', true); }
    else if(current_order == 'deadline_desc') { this.sortPackages('deadline', false); }
    else if(current_order == 'price_asc') { this.sortPackages('price', true); }
    else if(current_order == 'price_desc') { this.sortPackages('price', false); }

    // Make sure first hidden package is always on a new line
    for(let e of this.element.getElementsByClassName('col-start-1')) { e.classList.remove('col-start-1'); }
    var first_hidden_package = this.element.querySelector('[data-hidden]');
    if(first_hidden_package) { first_hidden_package.classList.add('col-start-1') }
  }

  reset() {
    this.provinceTarget.selectedIndex = 0;
    this.pickupTarget.selectedIndex = 0;
    this.sizeTarget.selectedIndex = 0;

    if(this.flatpickrInstance) {
      this.flatpickrInstance.clear();
    }

    this.filter();
  }

  isVisible(element, values) {
    const sizes = ['small', 'medium', 'large', 'xlarge', 'xxlarge'];

    if(values.pickup != "" && element.dataset.pickup != values.pickup) {
      return false;
    }

    if(values.province != "" && element.dataset.provinces.indexOf(values.province) == -1) {
      return false;
    }

    if(values.size != "" && sizes.indexOf(element.dataset.size) > sizes.indexOf(values.size)) {
      return false;
    }

    if(values.dates.size != 0 && !values.dates.has(element.dataset.date)) {
      return false;
    }

    if(values.noOffers && element.dataset.numOffers != '0') {
      return false;
    }

    if(values.available && element.dataset.unavailable == '') {
      return false;
    }

    if(values.starredOnly && !element.querySelector('.button--yellow')) {
      return false;
    }

    return true;
  }

  sortPackages(param, ascending) {
    var ordered = this.packageTargets.sort(function(a, b) {
      if(a.hasAttribute('data-highlight')) { return -1; }
      if(b.hasAttribute('data-highlight')) { return 1; }

      var x = a.dataset[param];
      var y = b.dataset[param];

      if(x == undefined) { return 1 }
      if(y == undefined) { return -1 }

      if(param == 'price') {
        x = parseInt(x);
        y = parseInt(y);
      }

      if(ascending) {
        if(x > y) { return 1; }
        else { return -1; }
      } else {
        if(x > y) { return -1; }
        else { return 1; }
      }
    });

    ordered = ordered.sort(function(a, b) {
      var x = a.hasAttribute('data-unavailable');
      var y = b.hasAttribute('data-unavailable');

      if(x == y) {
        return 0;
      } else if (x) {
        return 1;
      } else {
        return -1;
      }
    });

    ordered = ordered.sort(function(a, b) {
      var x = a.hasAttribute('data-hidden');
      var y = b.hasAttribute('data-hidden');

      if(x == y) {
        return 0;
      } else if (x) {
        return 1;
      } else {
        return -1;
      }
    });

    ordered = ordered.sort(function(a, b) {
      var x = a.hasAttribute('data-business');
      var y = b.hasAttribute('data-business');

      if(x == y) {
        return 0;
      } else if (x) {
        return -1;
      } else {
        return 1;
      }
    });

    ordered.forEach(p => {
      this.containerTarget.appendChild(p);
    });
  }

  runFilter(is_visible_cb) {
    var num_packages = 0;

    this.packageTargets.forEach(e => {
      if(is_visible_cb(e)) {
        e.classList.remove('hidden');
        num_packages++;
      } else {
        e.classList.add('hidden');
      }
    })

    if(this.hasCountTarget) {
      this.countTarget.innerHTML = num_packages;
    }

    if(this.hasEmptyTarget) {
      if(num_packages == 0) {
        this.emptyTarget.classList.remove('hidden');
      } else {
        this.emptyTarget.classList.add('hidden');
      }
    }
  }

  hideEmptyGroups() {
    for(let group of this.groupTargets) {
      var is_empty = group.querySelectorAll('[data-packages-filter-target="package"]:not(.hidden)').length == 0;
      group.classList.toggle('hidden', is_empty);
    }
  }

  activateVisibleState() {
    if(!this.hasTabsTarget) {
       return;
    }

    var current = this.tabsTarget.querySelector('.current');

    if(current && current.offsetParent) {
      return;
    }

    let tabs = Array.from(this.tabsTarget.children);
    if(this.reverseVisibilityValue) { tabs = tabs.reverse(); }

    for(let e of tabs) {
      if(e.offsetParent && e.dataset.packagesCount > 0 && !(e.dataset.packagesState == 'delivered' || e.dataset.packagesState == 'rejected' || e.dataset.packagesStates == 'cancelled')) {
        e.click();
        return;
      }
    }

    if(this.hasStateDropdownTarget) {
      let options = Array.from(this.stateDropdownTarget.children);
      if(this.reverseVisibilityValue) { options = options.reverse(); }

      for(let e of options) {
        if(e.dataset.packagesCount > 0 && !(e.value == 'delivered' || e.value == 'rejected' || e.value == 'cancelled')) {
          e.selected = true;
          e.parentNode.dispatchEvent(new Event("change"));
          return;
        }
      }
    }
  }
}
