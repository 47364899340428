import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  show() {
    if(window.fcWidget) {
      window.fcWidget.show();
      window.fcWidget.open();
      document.getElementById('fc_frame').style.display = 'block';
    }

    if(window.Futy) {
      window.Futy.open();
      document.getElementById('futy-container').style.display = 'block';
    }
  }
}
