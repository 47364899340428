import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  submit() {
    window.dispatchEvent(new Event("step-animator:load"));
    this.element.submit();
  }

  submitOnEnter(event) {
    if(event.key == "Enter") {
      this.submit();
    }
  }

  preventEnter(event) {
    if(event.key == "Enter") {
      event.preventDefault();
    }
  }
}
