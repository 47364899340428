import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  toggle() {
    if(this.isMenuOpen()) {
      this.close();
    } else {
      this.open();
    }
  }

  open() {
    document.body.classList.add('nav__open');
    history.pushState('nav__opened', '');
  }

  close() {
    document.body.classList.remove('nav__open');
  }

  isMenuOpen() {
    return document.body.classList.contains('nav__open');
  }
}
