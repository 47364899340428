import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    this.reloadListener = this.reload.bind(this);
    this.element.addEventListener("turbo:frame-load", this.reloadListener);
    this.element.src = this.urlValue;
  }

  disconnect() {
    this.element.removeEventListener("turbo:frame-load", this.reloadListener);
  }

  reload() {
    if(this.element.querySelector('[data-abort-polling]')) {
      console.log("Abort scanning");
      this.disconnect();
      return;
    }

    setTimeout(function() {
      this.element.reload();
    }.bind(this), 2000);
  }
}
