import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['button', 'input'];

  connect() {
    this.refresh();
  }

  refresh() {
    let enabled = this.isEnabled;

    this.buttonTargets.forEach(e => {
      e.classList.toggle('button--wizard-disabled', !enabled);

      if(enabled) {
        e.removeAttribute('disabled');
      } else {
        e.setAttribute('disabled', '');
      }
    });
  }

  get isEnabled() {
    for(let e of this.inputTargets) {
      if(e.checked) {
        return true;
      }
    }

    return false;
  }
}
