import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['button', 'element'];

  connect() {
    this.check();
    this.loadListener = this.check.bind(this);
    document.addEventListener("turbo:load", this.loadListener);
  }

  disconnect() {
    document.removeEventListener("turbo:load", this.loadListener);

  }

  check() {
    this.element.classList.toggle('current', this.element.href == document.location);
  }
}
