import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ['toggle'];
  static values = { target: String };

  connect() {
    this.target = document.getElementById(this.targetValue);
    this.update();
  }

  update() {
    if(this.isActive) {
      this.target.classList.add(...this.toggleClasses)
    } else {
      this.target.classList.remove(...this.toggleClasses)
    }
  }

  get isActive() {
    return this.element.checked;
  }
}
