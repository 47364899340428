import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['field', 'star'];

  connect() {
    this.endPreview();
  }

  select(event) {
    this.fieldTarget.value = this.previewValue;
  }

  preview(event) {
    this.previewValue = parseInt(event.target.dataset.starInputValue);
    this.showRating(this.previewValue);
  }

  showRating(value) {
    if(value == undefined) { value = 0 }

    for (let e of this.starTargets) {
      if (e.dataset.starInputValue <= value) {
        e.classList.add('text-yellow-600');
      } else {
        e.classList.remove('text-yellow-600');
      }
    }
  }

  endPreview() {
    this.showRating(parseInt(this.fieldTarget.value));
    this.previewValue = undefined;
  }
}
