import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input'];

  showPicker() {
    if(this.inputTarget.value != '') { return; }

    setTimeout(function() {
      try {
        this.inputTarget.showPicker();
      } catch(error) {
        this.inputTarget.focus();
      }
    }.bind(this), 1);
  }
}
