// TODO: Rename controller to something more fitting

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "toggle"];

  connect() {
    this.update()
  }

  update(event) {
    if(!this.hasInputTarget) { return; }

    let reveal = this.reveal_elements();

    this.toggleTargets.forEach(element => {
      let reveal_classes = [];
      let hide_classes = [];

      if(element.dataset['revealClasses']) { reveal_classes = element.dataset.revealClasses.split(' ') };
      if(element.dataset['hideClasses']) { hide_classes = element.dataset.hideClasses.split(' ') };

      if(reveal) {
        element.classList.add(...reveal_classes);
        element.classList.remove(...hide_classes);
      } else {
        element.classList.remove(...reveal_classes);
        element.classList.add(...hide_classes);
      }
    });
  }

  reveal_elements() {
    if(this.inputTarget.type == 'checkbox') {
      return this.inputTarget.checked;
    }

    const filled = (e) => !e.value || e.value == "";
    return !this.inputTargets.some(filled);
  }
}
