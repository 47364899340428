import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener('click', this.open.bind(this));
  }

  open(event) {
    event.preventDefault();

    this.modal = document.getElementById('modal');
    this.closer = document.getElementById('modal-close');

    this.modal.style.display = 'flex';

    this.closer_cb = this.close.bind(this);
    this.closer.addEventListener('click', this.closer_cb);

    this.modal_cb = function(event) {
      if(event.target == this.modal) {
        this.close(event);
      }
    }
    this.modal.addEventListener('click', this.modal_cb.bind(this));
  }

  close(event) {
    this.modal.style.display = 'none';
    this.closer.removeEventListener('click', this.closer_cb);
    this.closer.removeEventListener('click', this.modal_cb);
  }
}
