import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button", "start", "end"];

  connect() {
    this.start = undefined
    this.end = undefined

    let start = this.findTimeButton(this.startTarget.value);
    let end = this.findTimeButton(this.endTarget.value);

    if(start && end) {
      this.start = start;
      this.end = end;
      this.updateSelectionClasses();
    }
  }

  select(event) {
    event.preventDefault();

    if(!this.start || this.end) {
      this.startSelection(event);
    } else {
      this.endSelection(event);
    }
  }

  preview(event) {
    if(this.start && !this.end) {
      this.updateSelectionClasses(event.currentTarget);
    }
  }

  startSelection(event) {
    this.element.classList.add('time-selection--pending');
    this.start = event.currentTarget;
    this.end = undefined;
    this.updateSelectionClasses();

    this.startTarget.value = "";
    this.endTarget.value = "";
  }

  endSelection(event) {
    this.end = event.currentTarget;
    this.updateSelectionClasses();
    this.element.classList.remove('time-selection--pending');
    this.confirmSelection();
  }

  confirmSelection() {
    let start_time = this.start.dataset.time;
    let end_time = this.end.dataset.time;

    this.startTarget.value = start_time;
    this.endTarget.value = end_time;
  }

  updateSelectionClasses(end) {
    this.buttonTargets.forEach(e => e.classList.remove('time-selection--active', 'time-selection--start', 'time-selection--end'));

    let start = this.start;

    if(!start) { return; }
    if(!end) { end = this.end }
    if(!end) { end = this.start }


    if(start.compareDocumentPosition(end) == Node.DOCUMENT_POSITION_PRECEDING) {
      [start, end] = [end, start];
    }

    start.classList.add('time-selection--active', 'time-selection--start');
    end.classList.add('time-selection--active', 'time-selection--end');

    if(start == end) { return }

    let nextButton = start.nextSibling;

    while(nextButton != end) {
      nextButton.classList.add('time-selection--active');
      nextButton = nextButton.nextSibling;
    }
  }

  findTimeButton(time) {
    return this.buttonTargets.find(e => e.dataset.time == time);
  }
}
