import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['button', 'required'];

  connect() {
    this.check();
  }

  check() {
    this.buttonTarget.disabled = !this.enable();
  }

  enable() {
    var options = {};

    for (var e of this.requiredTargets) {
      // Check radio options later
      if(e.type == 'radio') {
        if(options[e.name] == undefined) {
          options[e.name] = false;
        }

        if(e.checked) {
          options[e.name] = true;
        }
      }
      else if(!e.checked) { return false; }
    }

    // Make sure a radio options is selected
    for (let [key, value] of Object.entries(options)) {
      if(!value) { return false }
    }

    return true;
  }
}
