import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['row', 'rows', 'template', 'toggle'];

  connect() {
    this.toggleRemoveButtons();
  }

  remove() {
    event.preventDefault();
    event.target.closest('[data-rows-target=row]').remove();
    this.toggleRemoveButtons();
  }

  add(event) {
    event.preventDefault();
    this.rowsTarget.appendChild(this.templateTarget.content.cloneNode(true));
    this.toggleRemoveButtons();
  }

  toggleRemoveButtons() {
    if(this.rowTargets.length == 1) {
      this.toggleTargets.forEach((e) => e.classList.add('pointer-events-none', 'opacity-0'));
    } else {
      this.toggleTargets.forEach((e) => e.classList.remove('pointer-events-none', 'opacity-0'));
    }
  }
}
