import { Controller } from "@hotwired/stimulus"

const Uppy = require('@uppy/core')
const Dashboard = require('@uppy/dashboard')
const DutchLocale = require('@uppy/locales/lib/nl_NL')
const ActiveStorageUpload = require('uppy-activestorage-upload')

require('@uppy/core/dist/style.css')
require('@uppy/dashboard/dist/style.css')

export default class extends Controller {
  static targets = ["field", "image", "name", "trigger"];
  static values = { allowedFiles: String }

  connect() {
    let direct_upload_url = this.element.dataset.directUploadUrl;

    let options = {
      allowMultipleUploads: true,
      // autoProceed: true,
      locale: DutchLocale,
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.tif', '.tiff', 'jfif'],
      },
    };

    let browse = "Afbeelding(en) toevoegen";

    if(this.allowedFilesValue == "analysis") {
      options.restrictions = {
        maxNumberOfFiles: 1,
        allowedFileTypes: ['.pdf'],
      }

      browse = "Afhaalbewijs toevoegen";
    }

    let uppy = new Uppy(options);

    uppy.use(ActiveStorageUpload, {
      directUploadUrl: direct_upload_url
    });

    uppy.use(Dashboard, {
      browserBackButtonClose: true,
      closeAfterFinish: true,
      proudlyDisplayPoweredByUppy: false,
      showProgressDetails: true,
      trigger: this.triggerTarget,
      locale: {
          strings: {
              browse: browse,
              dropPasteBoth: '%{browse}',
              dropPasteFiles: '%{browse}',
              dropPasteFolders: '%{browse}',
          },
      },
    });

    uppy.on('complete', (result) => {
      result.successful.forEach(file => {
        this.fieldTarget.value = file.response.signed_id
        this.setPreview(file)
      })


      this.element.closest('form').dispatchEvent(new Event('input'));

      uppy.reset();
    });
  }

  setPreview(file) {
    if(this.hasImageTarget) {
      this.imageTarget.src = file.preview;
    }

    if(this.hasNameTarget) {
      this.nameTarget.innerHTML = file.name;
    }
  }
}
