import { Controller } from '@hotwired/stimulus'
import tippy from 'tippy.js'

export default class extends Controller {
  static values = { placement: String, content: String }
  static targets = ['button', 'content'];

  initialize() {
    var content = "";

    if(this.element.hasAttribute('title')) {
      content = this.element.getAttribute('title');
      this.element.setAttribute('tooltip_title', content);
      this.element.removeAttribute('title');
    }

    if(this.element.hasAttribute('alt')) {
      content = this.element.getAttribute('alt');
    }

    if(this.hasContentValue) {
      content = this.contentValue;
    }

    if(this.hasContentTarget) {
      content = this.contentTarget.innerHTML;
    }

    var placement = undefined;
    if(this.hasPlacementValue) { placement = this.placementValue }

    tippy(this.element, {
      theme: 'ptu',
      content: content,
      allowHTML: true,
      placement: placement,
    });
  }

  disconnect() {
    if(this.element.hasAttribute('tooltip_title')) {
      this.element.setAttribute('title', this.element.getAttribute('tooltip_title'));
      this.element.removeAttribute('tooltip_title');
    }
  }
}
