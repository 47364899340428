import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['origin', 'destination', 'template', 'empty', 'present']

  add(event) {
    event.preventDefault();

    var origin = this.originTarget.value.trim();
    var destination = this.destinationTarget.value.trim();

    if(origin == "" || destination == "") {
      return;
    }

    var node = this.templateTarget.cloneNode(true);
    node.removeAttribute('data-target');
    node.classList.remove('hidden');

    node.querySelector('[data-origin-value]').value = origin;
    node.querySelector('[data-destination-value]').value = destination;
    node.querySelector('[data-origin-name]').innerHTML = origin;
    node.querySelector('[data-destination-name]').innerHTML = destination;

    this.templateTarget.parentNode.appendChild(node);
    this.templateTarget.parentNode.classList.remove('hidden');

    this.emptyTargets.forEach((e) => e.classList.add('hidden'));
    this.presentTargets.forEach((e) => e.classList.remove('hidden'));

    this.originTarget.value = "";
    this.destinationTarget.value = "";
  }
}
