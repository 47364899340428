import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input', 'switch', 'tab', 'select'];
  static values = { hide: Boolean };

  connect() {
    this.storeRequiredInputs();
    let first_switch = this.switchTargets[0];

    if(first_switch) {
      this.switchTo(first_switch.dataset.tabName);
    }

    if(this.hasSelectTarget) {
      this.switchTo(this.selectTarget.value);
    }
  }

  switch(event) {
    if(event.target.nodeName == "SELECT") {
      this.switchTo(event.target.value);
    } else {
      this.switchTo(event.target.dataset.tabName);
    }
  }

  switchTo(tab_name) {
    for(let tab of this.tabTargets) {
      if(tab_name && tab_name != "" && tab.dataset.tabName.split(',').includes(tab_name)) {
        this.requireInputs(tab);

        if(this.hideValue) {
          tab.classList.remove('hidden');
        } else {
          tab.classList.remove('opacity-0');
          tab.classList.remove('pointer-events-none');
        }
      } else {
        this.unrequireInputs(tab);

        if(this.hideValue) {
          tab.classList.add('hidden');
        } else {
          tab.classList.add('opacity-0');
          tab.classList.add('pointer-events-none');
        }
      }
    }

    for(let e of this.switchTargets) {
      if(e.dataset.tabName == tab_name) {
        e.classList.add('current');
      } else {
        e.classList.remove('current');
      }
    }

    if(this.hasInputTarget) {
      this.inputTarget.value = tab_name;
    }

    this.element.dispatchEvent(new Event("change"));
  }

  storeRequiredInputs() {
    for(let e of this.element.querySelectorAll("[required]")) {
      e.setAttribute('tabs-required', e.getAttribute('required'));
    }
  }

  unrequireInputs(root) {
    for(let e of root.querySelectorAll("[tabs-required]")) {
      e.removeAttribute('required');
    }
  }

  requireInputs(root) {
    for(let e of root.querySelectorAll("[tabs-required]")) {
      e.setAttribute('required', e.getAttribute('tabs-required'));
    }
  }
}
