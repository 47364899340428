import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "container" ]

  connect() {
    this.cleanup();
  }

  open(event) {
    if(event) { event.preventDefault() }

    pushOverlay(this, '#');
    this.containerTarget.classList.add('modal_open');
  }

  close(event) {
    if(event) { event.preventDefault() }

    popOverlay(this);

    this.containerTarget.classList.add('modal_closing');

    var animate = true;

    if(animate) {
      this.containerTarget.addEventListener('transitionend', this.cleanup.bind(this), { once: true, passive: true });
    } else {
      this.cleanup();
    }
  }

  cleanup() {
    this.containerTarget.classList.remove('modal_open');
    this.containerTarget.classList.remove('modal_closing');
  }
}
