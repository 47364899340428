import { ApplicationController, useDebounce } from 'stimulus-use'

export default class extends ApplicationController {
  static targets = ['search', 'street', 'number', 'numberAddon', 'postalCode', 'city', 'country'];
  static values = { addressUrl: String, stopInitialRefresh: Boolean, fullAddressRequired: Boolean };
  static debounces = ['refresh'];

  connect() {
    useDebounce(this, { wait: 250 })
    this.request_id = 0;

    if(this.hasSearchTarget) {
      let options = {
        bounds: {
          east: 7.2274985,
          north: 53.7253321,
          south: 50.7295671,
          west: 1.9193492,
        }
      }

      try {
        this.autocomplete = new google.maps.places.Autocomplete(this.searchTarget, options);

        // this.autocomplete.addListener("place_changed", function() {
        //   this.fillInAddress();
        // }.bind(this));
      } catch(e) {
        // Google Maps JS was not loaded, so just ignore it
      }
    }
  }

  fillInAddress() {
    return;

    const place = this.autocomplete.getPlace();
    let postal_code = "";
    this.streetTarget.value = "";

    for (const component of place.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number": {
          this.numberTarget.value = component.long_name;
          break;
        }

        case "route": {
          this.streetTarget.value = component.long_name;
          break;
        }

        case "postal_code": {
          postal_code = `${component.long_name}${postal_code}`;
          break;
        }

        case "postal_code_prefix": {
          postal_code = `${component.long_name}${postal_code}`;
          break;
        }

        case "postal_code_suffix": {
          postal_code = `${postal_code}-${component.long_name}`;
          break;
        }

        case "locality":
          this.cityTarget.value = component.long_name;
          break;

        case "country":
          this.countryTarget.value = component.long_name;
          break;
      }
    }

    this.postalCodeTarget.value = postal_code;

    if(this.streetTarget.value == "") {
      this.streetTarget.focus();
    } else {
      this.numberTarget.focus();
    }
  }
}
