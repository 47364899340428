import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['button'];

  connect() {
    this.check();
  }

  check() {
    if(this.hasFinishedLoading()) {
      this.finish();
    }
  }

  finish() {
    this.buttonTarget.classList.remove('button--wizard-disabled', 'button--loading');
  }

  hasFinishedLoading() {
    return this.element.querySelectorAll('img').length == 0;
  }
}
