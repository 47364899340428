import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["element", "check"];

  connect() {
    var checked = this.element.querySelector('[data-action="disabler#update"]:checked');

    if(this.hasCheckTarget) {
      checked = this.checkTarget.checked
      if(this.checkTarget.dataset.disablerReverse != undefined) { checked = !checked }
    }

    this.additionalElements = this.element.querySelectorAll('[data-disabler-classes]');
    this.updateElements(checked == null || checked == false);
  }

  update(event) {
    var target = event.currentTarget;
    var checked = target.checked;

    if(target.dataset.disablerReverse != undefined) { checked = !checked }

    if(checked) {
      this.updateElements(false);
      this.notifyRelatedControllers(target);
    } else {
      this.updateElements(true);
    }
  }

  updateElements(disabled) {
    this.elementTargets.forEach(element => {
      element.disabled = disabled;
    });

    this.additionalElements.forEach(element => {
      var classes = element.dataset.disablerClasses.split(' ');
      var enabler_classes = [];

      if(element.dataset.enablerClasses) {
        enabler_classes = element.dataset.enablerClasses.split(' ');
      }

      if(disabled) {
        element.classList.add(...classes);
        element.classList.remove(...enabler_classes);
      } else {
        element.classList.remove(...classes);
        element.classList.add(...enabler_classes);
      }
    });
  }

  notifyRelatedControllers(element) {
    var elements = document.querySelectorAll('[name="' + element.name + '"]');

    elements.forEach(e => {
      if(e != element) {
        e.dispatchEvent(new Event("change"));
      }
    });
  }
}
