import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input'];

  clear() {
    for(let e of this.inputTargets) {
      e.value = "";
    }
  }
}
